export const getScrollBar = () => {
  return {
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      width: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--chakra-colors-utils-250)',
      borderRadius: '24px'
    }
  }
}
