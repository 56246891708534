import { type Delivery, type PayDelivery } from '../../../../shared/model'
import {
  Badge, Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { OrderCard } from './OrderCard'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { OverviewModal } from './OverviewModal'
import { FaArrowsRotate, FaCalendarDays } from 'react-icons/fa6'
import { getSumPriceFromDelivery, getTotalAmountFromDelivery } from '../../../../shared/utils/delivery'
import { withObserver } from '../../../../shared/utils/stores'
import { getScrollBar } from '../../../../shared/utils/scrollbar'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'
import styles from '../../../../styles/DatePicker.module.css'
import { formatDate } from '../../../../shared/utils/date'

interface Props {
  orders?: Delivery
  getDeliveries: () => void
  payDelivery: (delivery: PayDelivery) => void
  isLoading: boolean
  deliveryDate: Date
  setDeliveryDate: (date: Date) => void
  deliveriesDates: string[]
}

const Component = (props: Props) => {
  const { orders, getDeliveries, payDelivery, isLoading, setDeliveryDate, deliveryDate, deliveriesDates } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [isNextDelivery, setIsNextDelivery] = useState(false)
  const [isPreviousDelivery, setIsPreviousDelivery] = useState(false)

  const getDateFormat = (date: Date) => {
    const formattedDate = format(date, 'd MMMM', { locale: nl })

    const today = format(new Date(), 'd MMMM', { locale: nl })

    return today === formattedDate ? 'Vandaag' : formattedDate
  }

  const getPreviousDeliveryDate = () => {
    const deliveriesDatesDESC = [...deliveriesDates].sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    for (const x of deliveriesDatesDESC) {
      const previousDeliveryDate = new Date(x)
      if (new Date(formatDate(previousDeliveryDate)).getTime() < new Date(formatDate(deliveryDate)).getTime()) {
        return previousDeliveryDate
      }
    }
    return deliveryDate
  }

  const getNextDeliveryDate = () => {
    for (const x of deliveriesDates) {
      const nextDeliveryDate = new Date(x)
      if (new Date(formatDate(nextDeliveryDate)).getTime() > new Date(formatDate(deliveryDate)).getTime()) {
        return nextDeliveryDate
      }
    }
    const tomorrow = deliveryDate
    tomorrow.setDate(deliveryDate.getDate() + 1)
    return new Date(formatDate(tomorrow))
  }

  const checkIfNoNextDelivery = (date: Date) => {
    for (const x of deliveriesDates) {
      const nextDeliveryDate = new Date(x)
      if (nextDeliveryDate.getTime() > new Date(formatDate(date)).getTime()) {
        return false
      }
    }
    return true
  }

  const checkIfNoPreviousDelivery = (date: Date) => {
    const deliveriesDatesDESC = [...deliveriesDates].sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    for (const x of deliveriesDatesDESC) {
      const nextDeliveryDate = new Date(x)
      if (nextDeliveryDate.getTime() < new Date(formatDate(date)).getTime()) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    setIsNextDelivery(checkIfNoNextDelivery(deliveryDate))
    setIsPreviousDelivery(checkIfNoPreviousDelivery(deliveryDate))
  }, [deliveryDate, deliveriesDates])

  const closeModal = () => {
    setModalOpen(false)
  }
  const border = useColorModeValue('utils.200', 'utils.dark.elevated.12dp')

  return (
      <>
            <Card bg={'none'} overflow={'hidden'} boxShadow={'none'} borderColor={border} borderWidth={1}>
                <CardHeader>
                    <HStack justifyContent={'space-between'}>
                        <Flex justifyContent={'space-between'} w={'100%'}>
                            <IconButton aria-label={'vorige bestelling'} title={'vorige bestelling'} isDisabled={isPreviousDelivery} onClick={() => { setDeliveryDate(getPreviousDeliveryDate()) }} icon={<FaChevronLeft/>}/>
                            <HStack>
                                <DatePicker selected={deliveryDate}
                                            portalId="datepicker-portal"
                                            popperClassName={styles.datePicker}
                                            onChange={(date: Date) => { setDeliveryDate(date ?? new Date()) }}
                                            customInput={
                                                    <IconButton aria-label={'bestelling datum selecteren'} icon={<FaCalendarDays/>}/>
                                            }
                                            />

                                    <Text fontWeight={'medium'}>{`${getDateFormat(deliveryDate)} ${!isLoading ? `(${getTotalAmountFromDelivery(orders)})` : ''}`}</Text>
                            </HStack>
                            <IconButton aria-label={'volgende bestelling'} title={'volgende bestelling'} onClick={() => { setDeliveryDate(getNextDeliveryDate()) }} icon={<FaChevronRight/>}/>
                        </Flex>
                        <IconButton isRound aria-label={'refresh orders'} onClick={getDeliveries} icon={<FaArrowsRotate/>}/>
                    </HStack>
                    <Divider mt={2}/>
                </CardHeader>
                <CardBody py={0} overflowY={'auto'} css={getScrollBar()}>
                    {isLoading
                      ? <Center>
                            <Spinner/>
                        </Center>
                      : <>
                    {orders && orders.orders.length > 0
                      ? <Stack spacing={2}>
                            {orders.orders.map((o, index) => (
                                <OrderCard key={index} order={o}/>
                            ))}
                        </Stack>
                      : <Text pt={4} color={'utils.250'}>Nog geen bestelling</Text>
                    }
                        </>
                    }

                </CardBody>
                <CardFooter>
                    {orders && orders.orders.length > 0 && !isLoading &&
                    <Stack w={'100%'}>
                        <Divider mb={2}/>
                        <Flex justifyContent={'space-between'} alignItems={'center'} pb={2}>
                            <HStack>
                                <Text fontWeight={500}>{`Totaal: ${getSumPriceFromDelivery(orders)}`}</Text>
                                {orders && orders.orders.length > 0 && !isLoading &&
                                    <>
                                        {orders.usernamePayer !== ''
                                          ? <Badge colorScheme='green'>betaald</Badge>
                                          : <Badge>nog betalen</Badge>
                                        }
                                    </>
                                }
                            </HStack>
                            <Button variant={'utilsButtonPrimary'} alignSelf={'end'} onClick={() => { setModalOpen(true) }}
                            >Overzicht</Button>
                        </Flex>

                    </Stack>
                    }
                </CardFooter>
            </Card>
            {orders &&
                <OverviewModal modalOpen={modalOpen} closeModal={closeModal} orders={orders} payDelivery={payDelivery}/>
            }
        </>
  )
}

export const OrderList = withObserver(Component)
