import {
  Drawer, DrawerBody, DrawerContent,
  DrawerOverlay,
  Grid,
  Heading, HStack,
  IconButton, Image, useColorMode,
  useColorModeValue
} from '@chakra-ui/react'
import { FaBars } from 'react-icons/fa'
import React, { useState } from 'react'
import { LoginButton } from '../side-menu/components/LoginButton'
import { SideMenu } from '../side-menu/SideMenu'

interface Props {
  title: string
  image?: string
  orderMode: boolean
  selected?: string
  refresh: () => void
}

export const Header = (props: Props) => {
  const { title, orderMode, selected, image, refresh } = props
  const bg = useColorModeValue('white', 'utils.bg.dark')
  const bgHeader = useColorModeValue('white', 'utils.dark.elevated.02dp')

  const [showSideMenu, setShowSideMenu] = useState(false)
  const { colorMode } = useColorMode()

  const closeSideMenu = () => {
    setShowSideMenu(false)
  }

  return (
      <>
        <Grid boxShadow={'0px 2px 4px 0px rgba(0, 0, 0, 0.25)'} bg={bgHeader} templateColumns={'5rem auto 5rem'} width={'100%'} h={'100%'} alignItems={'center'}>
            <IconButton aria-label={'open side menu'} onClick={() => { setShowSideMenu(true) }} variant={'ghost'} icon={<FaBars/>}/>
            <HStack>
                {image &&
                    <Image h={'32px'} src={`data:image/svg+xml;base64,${image}`} filter={colorMode === 'light' ? '' : 'invert(99%) sepia(99%) saturate(0%) hue-rotate(217deg) brightness(105%) contrast(100%)'}/>
                }
                <Heading fontSize={24}>{title}</Heading>
            </HStack>
            <LoginButton/>
        </Grid>
    <Drawer isOpen={showSideMenu} placement='left' onClose={closeSideMenu}>
        <DrawerOverlay/>
        <DrawerContent w={'5rem !important'} boxShadow={'md'} bg={bg}>
            <DrawerBody pt={'0px !important'} px={0} pb={0}>
                <SideMenu closeSideMenu={closeSideMenu} orderMode={orderMode} selected={selected} refresh={refresh}/>
            </DrawerBody>
        </DrawerContent>
    </Drawer>
          </>
  )
}
