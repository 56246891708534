import { type Order } from '../../../../shared/model'
import React from 'react'
import { Box, HStack, IconButton, Image, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { FaTimes } from 'react-icons/fa'
import { NoImagePlaceholder } from '../../../../shared/components/NoImagePlaceholder'

interface Props {
  order: Order
  handleDelete: (orderId: number) => void
}

export const PersonalOrderCard = (props: Props) => {
  const { order, handleDelete } = props
  const optionsText = useColorModeValue('gray.500', 'utils.200')

  const getOptions = () => {
    const options: string[] = []
    Object.entries(order.chosenOptions).map(([type, option]) => (
      options.push(option)
    ))
    return options.join(', ')
  }

  return (
      <Box>
        <HStack
            h={'inherit'}
            justifyContent={'space-between'}
        >
            <HStack>
                {order.menuItem.image !== '' && order.menuItem.image
                  ? <Image borderRadius={'lg'} h={70} w={'70px'} objectFit={'cover'} src={`data:image/jpeg;base64,${order.menuItem.image}`} alt={order.menuItem.name}/>
                  : <NoImagePlaceholder width={70} borderRadius/>
                }
                <VStack h={'100%'} alignItems={'start'} justifyContent={'center'} gap={0}>
                    <Text fontSize={'sm'} fontWeight={400}>
                        {`${order.menuItem.name}${order.amount > 1 ? ` (${order.amount}x)` : ''}`}
                    </Text>
                    <Text fontSize="xs" color={optionsText}>
                        {getOptions()}
                    </Text>
                    {order.comment &&
                        <Text fontSize="xs" color={optionsText}>
                            {order.comment}
                        </Text>
                    }
                    <Text fontSize={'sm'} fontWeight={500}>
                        {`€${order.sumPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
                    </Text>
                </VStack>
            </HStack>
                <IconButton aria-label={'delete-order'} isRound variant={'utilsButtonDelete'} onClick={() => { handleDelete(order.id) }} icon={<FaTimes/>}/>
        </HStack>
    </Box>
  )
}
