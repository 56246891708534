import { type Variation } from '../../shared/model'
import {
  Card, CardBody,
  CardHeader, Divider,
  FormLabel, Grid,
  HStack,
  IconButton,
  Input, Switch,
  useColorModeValue
} from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'
import React, { useEffect, useState } from 'react'
import { FaTrashCan } from 'react-icons/fa6'
import { MenuOption } from './MenuOption'
import { proxyToJs, withObserver } from '../../shared/utils/stores'

interface Props {
  options: Variation
  updateVariation: (variation: Variation) => void
  deleteVariation: (variation: Variation) => void
}

const Component = (props: Props) => {
  const bg = useColorModeValue('white', 'utils.dark.elevated.12dp')
  const { options, updateVariation, deleteVariation } = props
  const [updatedOptions, setUpdatedOptions] = useState(proxyToJs(options))

  useEffect(() => {
    updateVariation(updatedOptions)
  }, [updatedOptions])

  const updateKey = (key: string, newKey: string) => {
    if (key === newKey) {
      return
    }
    const tempOptions = { ...updatedOptions }
    Object.entries(updatedOptions.types).forEach(([type, price]) => {
      if (type === key) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete Object.assign(tempOptions.types, { [newKey]: tempOptions.types[key] })[key]
      }
    })
    setUpdatedOptions(tempOptions)
  }

  const updatePrice = (key: string, price: number) => {
    const tempOptions = { ...updatedOptions }
    tempOptions.types[key] = price
    setUpdatedOptions(tempOptions)
  }

  const createNewOption = () => {
    const tempOptions = { ...updatedOptions }
    tempOptions.types[''] = 0
    setUpdatedOptions(tempOptions)
  }

  const deleteOption = (key: string) => {
    const tempOptions = { ...updatedOptions }
    Object.entries(updatedOptions.types).forEach(([type, price]) => {
      if (type === key) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete tempOptions.types[key]
      }
    })
    setUpdatedOptions(tempOptions)
  }

  return (
        <Card bg={bg} w={'50rem'}>
            <CardHeader>
                <HStack justifyContent={'space-between'}>
                        <Input
                            defaultValue={updatedOptions.name}
                            w={'15rem'}
                            placeholder={'Optie naam...'}
                            onBlur={(e) => { setUpdatedOptions({ ...updatedOptions, name: e.target.value }) }}
                        />
                        <HStack>
                            <FormLabel>Verplicht</FormLabel>
                            <Switch
                                onChange={(e) => { setUpdatedOptions({ ...updatedOptions, required: e.target.checked }) }}
                                defaultChecked={updatedOptions.required}
                            />
                        </HStack>
                    <IconButton aria-label={'delete option'} onClick={() => { deleteVariation(updatedOptions) }} isRound variant={'utilsButtonDelete'}
                                icon={<FaTrashCan/>}/>
                </HStack>
                <Divider mt={4}/>
            </CardHeader>
            <CardBody>
                <Grid templateColumns={'1fr 1fr'} rowGap={4}>
                    {Object.entries(updatedOptions.types).map(([type, price], i) => (
                        <MenuOption key={`${type}${i}`} optionKey={type} price={price} updateKey={updateKey}
                                    updatePrice={updatePrice} deleteOption={deleteOption}/>
                    ))}
                    <IconButton w={'20rem'} aria-label={'add new option'} icon={<FaPlus/>} onClick={() => {
                      createNewOption()
                    }}
                                variant={'utilsButtonPrimaryOutline'}/>
                </Grid>

            </CardBody>
        </Card>
  )
}

export const MenuOptionsCard = withObserver(Component)
