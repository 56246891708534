import React, { useEffect, useState } from 'react'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { IconButton, useColorModeValue } from '@chakra-ui/react'

interface Props {
  favorite: boolean
  updateFavorite: (isFavorite: boolean) => void
}

export const FavoriteIcon = (props: Props) => {
  const { favorite, updateFavorite } = props
  const [isFavorite, setFavorite] = useState(favorite)
  const heart = useColorModeValue('#d00e22', 'red')
  const heartDisabled = useColorModeValue('#3b3b3b', '#dfdfdf')
  const heartHover = useColorModeValue('#252525', '#FFF')

  useEffect(() => {
    setFavorite(favorite)
  }, [favorite])

  const update = (favorite: boolean) => {
    setFavorite(favorite)
    updateFavorite(favorite)
  }

  return (
        <>
           <IconButton variant={'ghost'} color={heartDisabled} _hover={{ color: heartHover, transform: 'scale(1.2, 1.2)' }} aria-label={'favorite item'} onClick={(e) => {
             e.stopPropagation()
             update(!isFavorite)
           }} fontSize={24} icon={isFavorite ? <FaHeart color={heart}/> : <FaRegHeart/>}/>
        </>
  )
}
