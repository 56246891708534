import { useEffect, useState } from 'react'
import { proxyToJs } from '../shared/utils/stores'
import { MenuStore } from '../shared/stores/menu-store'
import { type Menu, type MenuItem, type MenuNameDTO, type Tenant, type Variation } from '../shared/model'
import { TenantStore } from '../shared/stores/tenant-store'
export const useMenuEditorState = () => {
  const [selectedMenu, setSelectedMenu] = useState<Menu>(proxyToJs(MenuStore.selectedMenu))
  const [allMenus, setAllMenus] = useState<Menu[]>([])
  const [menuNames, setMenuNames] = useState<MenuNameDTO[]>(proxyToJs(MenuStore.menuNameList))
  const [showAlert, setShowAlert] = useState(false)
  const [tenants, setTenants] = useState<Tenant[]>(proxyToJs(TenantStore.tenants))

  const openAlert = () => {
    setShowAlert(true)
  }

  const closeAlert = () => {
    setShowAlert(false)
  }

  useEffect(() => {
    setSelectedMenu(MenuStore.selectedMenu)
  }, [MenuStore.selectedMenu])

  useEffect(() => {
    setAllMenus(MenuStore.menus)
  }, [MenuStore.menus])

  useEffect(() => {
    setTenants(TenantStore.tenants)
  }, [TenantStore.tenants])

  useEffect(() => {
    const getAllMenus = async () => {
      await MenuStore.getAllMenus()
    }

    getAllMenus()
  }, [])

  const updateTenants = (tenants: Tenant[]) => {
    const tempSelectedMenu = { ...selectedMenu, tenants }
    setSelectedMenu(proxyToJs(tempSelectedMenu))
  }

  const updateVariation = (variation: Variation) => {
    const tempSelectedMenu = { ...selectedMenu }
    const index = tempSelectedMenu.options.findIndex(x => x.id === variation.id)
    if (index !== -1) {
      tempSelectedMenu.options[index] = variation
      setSelectedMenu(tempSelectedMenu)
      updateMenuItemVariations(variation)
    }
  }

  const updateMenuItemVariations = (variation: Variation) => {
    const tempSelectedMenu = { ...selectedMenu }
    tempSelectedMenu.items.forEach(x => {
      const optionIndex = x.options.findIndex(o => o.id === variation.id)
      if (optionIndex !== -1) {
        x.options[optionIndex] = variation
      } else {
        const deleteIndex = x.options.findIndex(o => o.id === variation.id)
        if (deleteIndex !== -1) {
          x.options.splice(deleteIndex, 1)
        }
      }
    })
    setSelectedMenu(tempSelectedMenu)
  }

  const deleteVariation = (variation: Variation) => {
    const tempSelectedMenu = { ...selectedMenu }
    const index = tempSelectedMenu.options.findIndex(x => `${x.name}${x.id}` === `${variation.name}${variation.id}`)
    if (index !== -1) {
      tempSelectedMenu.options.splice(index, 1)
      setSelectedMenu(tempSelectedMenu)
      updateMenuItemVariations(variation)
    }
  }

  const addVariation = () => {
    const tempSelectedMenu = { ...selectedMenu }
    tempSelectedMenu.options.push({ id: -1, name: '', types: {}, required: false })
    setSelectedMenu(tempSelectedMenu)
  }

  const addMenuItem = () => {
    const tempSelectedMenu = { ...selectedMenu }
    tempSelectedMenu.items.push({
      id: -1,
      name: '',
      options: [],
      price: 0,
      description: '',
      variablePrice: false,
      image: '',
      itemOrder: tempSelectedMenu.items.length
    })
    setSelectedMenu(proxyToJs(tempSelectedMenu))
  }

  const updateMenuItem = (menuItem: MenuItem, oldName: string) => {
    const tempSelectedMenu = { ...selectedMenu }
    const index = tempSelectedMenu.items.findIndex(x => `${x.name}${x.id}` === `${oldName}${menuItem.id}`)
    if (index !== -1) {
      tempSelectedMenu.items[index] = menuItem
      setSelectedMenu(proxyToJs(tempSelectedMenu))
    }
  }

  const deleteMenuItem = (menuItem: MenuItem) => {
    const tempSelectedMenu = { ...selectedMenu }
    const index = tempSelectedMenu.items.findIndex(x => `${x.name}${x.id}` === `${menuItem.name}${menuItem.id}`)
    if (index !== -1) {
      tempSelectedMenu.items.splice(index, 1)
      setSelectedMenu(proxyToJs(tempSelectedMenu))
    }
  }

  const addMenu = (name: string) => {
    const newMenu: Menu = { name, image: '', options: [], items: [], tenants: [] }
    newMenu.options.push({ id: -1, name: '', types: {}, required: false })
    newMenu.items.push({ id: -1, name: '', options: [], price: 0, description: '', variablePrice: false, image: '', itemOrder: 0 })
    setAllMenus([...allMenus, newMenu])
    setSelectedMenu(newMenu)
    MenuStore.addMenu(newMenu)
  }

  const updateMenu = (name: string, image: string) => {
    setSelectedMenu({ ...selectedMenu, name, image })
  }

  const removeMenu = () => {
    const index = allMenus.findIndex(x => x.name === selectedMenu.name)
    if (index !== -1) {
      const updatedMenus = [...allMenus]
      updatedMenus.splice(index, 1)
      setAllMenus(updatedMenus)
      setSelectedMenu(updatedMenus[index - 1] ?? {})
    }
    MenuStore.deleteMenu(selectedMenu.name)
    closeAlert()
  }

  const updateOrder = (menuItems: MenuItem[]) => {
    for (let i = 0; i < menuItems.length; i++) {
      menuItems[i].itemOrder = i
    }
    return menuItems
  }

  return {
    openAlert,
    closeAlert,
    showAlert,
    updateVariation,
    addVariation,
    deleteVariation,
    addMenuItem,
    deleteMenuItem,
    updateMenuItem,
    addMenu,
    updateMenu,
    removeMenu,
    selectedMenu,
    allMenus,
    setSelectedMenu,
    menuNames,
    setMenuNames,
    updateOrder,
    tenants,
    updateTenants
  }
}
