import { Icon, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import React from 'react'
import { type IconType } from 'react-icons'
import { useNavigate } from 'react-router-dom'

interface Props {
  icon: IconType
  name: string
  selected: boolean
  url: string
}

export const NavigationItem = (props: Props) => {
  const { name, icon, selected, url } = props
  const iconColor = useColorModeValue('black', 'white')
  const selectedBg = useColorModeValue('utils.810', 'utils.dark.elevated.02dp')
  const navigate = useNavigate()

  return (
        <VStack onClick={() => {
          navigate(url)
        }} bg={selected ? selectedBg : 'none'} h={'8vh'} maxH={'5rem'} borderLeft={selected ? '.25rem solid' : 'none'} p={'1vh'} w={'inherit'} borderColor={'utils.900'} gap={0} justifyContent={'center'} _hover={{ bg: selectedBg, cursor: 'pointer' }}>
                    <Icon as={icon} w={'70%'} h={'3vh'} pb={1} color={iconColor}/>
            <Text fontSize={'2xs'} fontWeight={500} color={iconColor}>{name}</Text>
        </VStack>
  )
}
