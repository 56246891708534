import { withObserver } from '../../shared/utils/stores'
import { Card, FormControl, FormLabel, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { FaInfoCircle } from 'react-icons/fa'
import React from 'react'
import { type Tenant } from '../../shared/model'
import { Select } from 'chakra-react-select'

interface Props {
  tenants: Tenant[]
  selectedTenants: Tenant[]
  setTenants: (tenants: Tenant[]) => void
}

const Component = (props: Props) => {
  const bg = useColorModeValue('white', 'utils.dark.elevated.12dp')
  const infoText = useColorModeValue('utils.gray', 'white')
  const { tenants, selectedTenants, setTenants } = props

  const updateTenants = (options: [{ value: number, label: string }]) => {
    const tempTenants: Tenant[] = []
    options.forEach(x => {
      const tenant = tenants.find(t => x.value === t.id)
      if (tenant) {
        tempTenants.push(tenant)
      }
    })
    setTenants(tempTenants)
  }

  return (
        <Card bg={bg} w={'50rem'} p={4} h={'inherit'}>
    <HStack gap={4}>
    <FormControl>
    <FormLabel>Organisaties</FormLabel>
    <Select
        isMulti
        placeholder={'Selecteer organisaties...'}
        onChange={(e: any) => {
          updateTenants(e)
        }}
        value={selectedTenants.map(x => {
          return { label: x.name, value: x.id }
        })}
        options={tenants.map(x => {
          return { label: x.name, value: x.id }
        })}
    />
    </FormControl>
    </HStack>
            <HStack pt={2}>
                <Icon color={infoText} as={FaInfoCircle} flexShrink={0}/>
                <Text as={'i'} color={infoText}>
                    {'Voeg hier alle organisaties toe die dit menu mogen zien.'}
                </Text>
            </HStack>
        </Card>
  )
}

export const MenuTenantCard = withObserver(Component)
