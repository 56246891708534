import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useColorModeValue, useToast
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { type MenuItem, type UserOrder, type Variation, type VariationDTO } from '../../shared/model'
import { Field, Form, Formik } from 'formik'
import { MenuStore } from '../../shared/stores/menu-store'
import { formatBackEndDate, isDateInpast } from '../../shared/utils/date'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { AuthStore } from '../../shared/stores/auth-store'
import { LoginButton } from '../../shared/layout/side-menu/components/LoginButton'

interface Props {
  menuItem: MenuItem
  modalOpen: boolean
  closeModal: () => void
  postOrder: (order: UserOrder) => void
  deliveryDate: Date
}

interface SelectedOption {
  name: string
  price: number
}

export const FoodModal = (props: Props) => {
  const { menuItem, modalOpen, closeModal, postOrder, deliveryDate } = props
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([])
  const [chosenVariations, setChosenVariations] = useState<VariationDTO[]>([])
  const [additionalCosts, setAdditionalCosts] = useState(0)
  const bg = useColorModeValue('utils.40', 'utils.bg.dark')
  const bgCardHeader = useColorModeValue('white', 'utils.dark.elevated.06dp')
  const toast = useToast()

  const updateSelectedOptions = (selectedOption: string, optionId: number) => {
    const options = menuItem.options.find(x => x.id === optionId)
    if (!options) return
    const additionalCosts = options.types[selectedOption]
    const index = selectedOptions.findIndex(x => x.name === options.name)
    const tempSelectedOptions = selectedOptions
    if (index >= 0) {
      tempSelectedOptions[index] = { name: options.name, price: additionalCosts }
    } else {
      tempSelectedOptions.push({ name: options.name, price: additionalCosts })
    }
    setSelectedOptions(tempSelectedOptions)
    setAdditionalCosts(getAdditionalCosts())
  }

  const getAdditionalCosts = () => {
    let additionalCosts = 0
    selectedOptions.forEach(x => {
      additionalCosts += x.price
    })
    return additionalCosts
  }

  const insertChosenVariation = (variation: Variation, selectedOption: string): VariationDTO[] => {
    const index = chosenVariations.findIndex(x => x.id === variation.id)
    const tempChosenVariations = chosenVariations
    if (index >= 0) {
      tempChosenVariations[index] = { id: variation.id, chosen: selectedOption }
    } else {
      tempChosenVariations.push({ id: variation.id, chosen: selectedOption })
    }
    setChosenVariations(tempChosenVariations)
    return tempChosenVariations
  }

  const removeChosenVariation = (variation: Variation): VariationDTO[] => {
    const index = chosenVariations.findIndex(x => x.id === variation.id)
    const tempChosenVariations = chosenVariations
    if (index >= 0) {
      tempChosenVariations.splice(index, 1)
    }
    setChosenVariations(tempChosenVariations)
    return tempChosenVariations
  }

  return (
        <Modal isOpen={modalOpen} onClose={closeModal}>
            <ModalOverlay/>
            <ModalContent bg={bg} borderRadius={'lg'}>
                <ModalHeader padding={4} bg={bgCardHeader}>
                    <Flex columnGap={2}>
                        {menuItem.image !== '' && menuItem.image &&
                        <Image borderRadius={'lg'} h={108} src={`data:image/jpeg;base64,${menuItem.image}`}/>
                        }
                        <Flex flexDirection={'column'} justifyContent={'space-evenly'}>
                            <HStack>
                                <Text fontWeight={500} fontSize={20}>{menuItem.name}</Text>
                            </HStack>
                            <Text fontWeight={400} fontSize={14}>{menuItem.description}</Text>
                        </Flex>
                    </Flex>
                    <ModalCloseButton/>
                </ModalHeader>
                <Formik
                    initialValues={{
                      amount: 1,
                      comment: '',
                      menuName: MenuStore.getSelectedMenuName(),
                      menuItemId: menuItem.id,
                      chosenVariations: [],
                      date: deliveryDate
                    }}
                    onSubmit={async (data, { setSubmitting, setErrors }) => {
                      setSubmitting(true)
                      AuthStore.performAuthCheck()
                      if (!isDateInpast(data.date)) {
                        postOrder({ ...data, date: formatBackEndDate(deliveryDate) })
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Bestel datum ligt in het verleden',
                          status: 'error',
                          duration: 9000,
                          isClosable: true
                        })
                      }
                      setSubmitting(false)
                      closeModal()
                    }}
                >
                    {({ isSubmitting, setFieldValue, values, handleSubmit, errors }) => (
                        <Form
                            onSubmit={handleSubmit}
                        >
                            <ModalBody maxH={'50vh'} overflow={'auto'} backgroundColor={bg} padding={4} rowGap={4}
                                       boxShadow={'inset 0px 10px 5px -5px #00000012, inset 0px -10px 5px -5px #00000012'}>

                                <Flex flexDirection={'column'} rowGap={4}>
                                    {menuItem.options.map(x => (
                                        <Box key={x.id}>
                                            <FormControl isRequired={x.required}>
                                                <FormLabel maxW={'80%'}>
                                                    {x.name}
                                                </FormLabel>
                                                <Field
                                                    as={Select}
                                                    variant={'utilsSelect'}
                                                    id={x.name}
                                                    name={x.name}
                                                    defaultValue={''}
                                                    onChange={(e: any) => {
                                                      if (e.target.value !== 'empty') {
                                                        updateSelectedOptions(e.target.value, x.id)
                                                        setFieldValue('chosenVariations', insertChosenVariation(x, e.target.value))
                                                      } else {
                                                        setFieldValue('chosenVariations', removeChosenVariation(x))
                                                      }
                                                    }}
                                                >
                                                    <option hidden disabled
                                                            value="">{`Selecteer ${x.name}`}</option>
                                                    {!x.required &&
                                                        <option value={'empty'}>Geen</option>
                                                    }
                                                    {Object.entries(x.types).map(([option, price]) => (
                                                        <option key={option}
                                                                value={option}>{price > 0 ? `${option} (+€${price})` : option}</option>
                                                    ))}
                                                </Field>
                                            </FormControl>
                                        </Box>
                                    ))}
                                    <Box>
                                        <FormControl>
                                            <FormLabel htmlFor="comment" maxW={'80%'}>
                                                {'Opmerking'}
                                            </FormLabel>
                                            <Field
                                                as={Textarea}
                                                id={'comment'}
                                                name={'comment'}
                                                variant={'utilsTextarea'}
                                            />
                                        </FormControl>
                                    </Box>
                                </Flex>
                            </ModalBody>
                            <ModalFooter justifyContent={'space-between'} bg={bgCardHeader}>
                                <HStack>
                                    <FormControl>
                                        <Field
                                            as={IconButton}
                                            variant={'utilsButtonSecondary'}
                                            size={'md'}
                                            id={'amount'}
                                            name={'amount'}
                                            isDisabled={values.amount <= 1}
                                            isRound
                                            aria-label={'subtract-item'}
                                            onClick={() => {
                                              setFieldValue('amount', values.amount - 1)
                                            }}
                                            icon={<FaMinus/>}/>
                                    </FormControl>
                                    <Text fontWeight={'bold'}>{values.amount}</Text>
                                    <FormControl>
                                        <Field as={IconButton}
                                               variant={'utilsButtonSecondary'}
                                               size={'md'}
                                               id={'amount'}
                                               name={'amount'}
                                               isRound
                                               aria-label={'add-item'}
                                               onClick={() => {
                                                 setFieldValue('amount', values.amount + 1)
                                               }}
                                               icon={<FaPlus/>}/>
                                    </FormControl>
                                    <Text pl={4} fontWeight={'bold'}
                                          fontSize={'xl'}>{`€${(values.amount * (menuItem.price + additionalCosts)).toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</Text>
                                </HStack>
                                <AuthenticatedTemplate>
                                <Button variant={'utilsButtonPrimary'} isLoading={isSubmitting} type={'submit'}>Toevoegen</Button>
                                </AuthenticatedTemplate>
                                <UnauthenticatedTemplate>
                                    <LoginButton/>
                                </UnauthenticatedTemplate>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>

  )
}
