import React from 'react'
import './App.css'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { RouterProvider } from 'react-router-dom'
import { router } from './Routes'
import { useTheme } from './shared/theme/theme'
import { MsalProvider } from '@azure/msal-react'

interface Props {
  pca: IPublicClientApplication
}

function App ({ pca }: Props) {
  const theme = useTheme()

  return (
        <MsalProvider instance={pca}>
            <ChakraProvider theme={theme}>
                <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
                <RouterProvider router={router}/>
            </ChakraProvider>
        </MsalProvider>
  )
}

export default App
