import { type Delivery, type PayDelivery, type UserDelivery } from '../../../shared/model'
import React from 'react'
import { PersonalOrderList } from './components/PersonalOrderList'
import { Grid, Stack, useColorModeValue, Text } from '@chakra-ui/react'
import { Deliveries } from './components/Deliveries'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { LoginButton } from '../../../shared/layout/side-menu/components/LoginButton'
import { withObserver } from '../../../shared/utils/stores'

interface Props {
  personalOrders: UserDelivery
  orders: Delivery[]
  getDeliveries: () => void
  payDelivery: (delivery: PayDelivery) => void
  deletePersonalOrder: (deliveryId: number, orderId: number) => void
  isLoading: boolean
  deliveryDate: Date
  setDeliveryDate: (date: Date) => void
  deliveriesDates: string[]
}

const Component = (props: Props) => {
  const { personalOrders, orders, getDeliveries, payDelivery, deletePersonalOrder, isLoading, setDeliveryDate, deliveryDate, deliveriesDates } = props
  const bg = useColorModeValue('white', 'utils.dark.elevated.08dp')
  const instance = useMsal()

  const refreshDelivery = () => {
    if (instance.accounts.length > 0) {
      getDeliveries()
    }
  }

  return (
        <>
            <Grid templateRows={'auto auto 1fr'} w={'inherit'} h={{ base: '100%', lg: 'inherit' }} p={4} pt={{ base: 12, lg: 8 }} bg={bg}
                  boxShadow={'md'} rowGap={4}>
                <AuthenticatedTemplate>
                    <PersonalOrderList isLoading={isLoading} deletePersonalOrder={deletePersonalOrder} personalOrders={personalOrders}/>
                    <Deliveries isLoading={isLoading} deliveries={orders} getDeliveries={refreshDelivery} payDelivery={payDelivery} deliveryDate={deliveryDate} setDeliveryDate={setDeliveryDate} deliveriesDates={
                        [...deliveriesDates].sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                    }/>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Stack justifyContent={'center'} w={'inherit'} h={{ base: '100%', lg: 'inherit' }} alignContent={'center'}>
                        <Text align={'center'}>
                            {'Login om de bestellingen te bekijken'}
                        </Text>
                        <LoginButton/>
                    </Stack>
                </UnauthenticatedTemplate>

            </Grid>
        </>
  )
}

export const OrdersTodayList = withObserver(Component)
