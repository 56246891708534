import { Image, Text, useColorMode, useColorModeValue, VStack } from '@chakra-ui/react'
import React from 'react'
import { type MenuNameDTO } from '../../../model'
import { NoImagePlaceholder } from '../../../components/NoImagePlaceholder'

interface Props {
  name: string
  selected: boolean
  onClick: (menu: MenuNameDTO) => void
  menu: MenuNameDTO
}

export const MenuItem = (props: Props) => {
  const { name, selected, onClick, menu } = props
  const { colorMode } = useColorMode()
  const iconColor = useColorModeValue('black', 'white')
  const selectedBg = useColorModeValue('utils.810', 'utils.dark.elevated.02dp')

  return (
        <VStack onClick={() => {
          onClick(menu)
        }} bg={selected ? selectedBg : 'none'} h={'8vh'} maxH={'5rem'} borderLeft={selected ? '.25rem solid' : 'none'} p={'1vh'} w={'inherit'} borderColor={'utils.900'} gap={0} justifyContent={'center'} _hover={{ bg: selectedBg, cursor: 'pointer' }}>
                {menu.image !== '' && menu.image
                  ? <Image h={'100%'} src={`data:image/svg+xml;base64,${menu.image}`} filter={colorMode === 'light' ? '' : 'invert(99%) sepia(99%) saturate(0%) hue-rotate(217deg) brightness(105%) contrast(100%)'}/>
                  : <NoImagePlaceholder/>
                }
            <Text fontSize={'2xs'} fontWeight={500} color={iconColor}>{name}</Text>
        </VStack>
  )
}
