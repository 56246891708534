import { Center, Divider, Grid, IconButton, Image, useColorMode, useColorModeValue, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MenuItem } from './components/MenuItem'
import { FaClipboardList, FaMoon, FaPen, FaSun, FaUtensils } from 'react-icons/fa'
import { withObserver } from '../../utils/stores'
import { MenuStore } from '../../stores/menu-store'
import { type MenuNameDTO, type Tenant } from '../../model'
import { LoginButton } from './components/LoginButton'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { NavigationItem } from './components/NavigationItem'
import { TenantStore } from '../../stores/tenant-store'
import { getTenantId, setTenantId } from '../../tenant/tenant'
import { TenantPicker } from './components/TenantPicker'

interface Props {
  orderMode: boolean
  selected?: string
  closeSideMenu?: () => void
  refresh: () => void
}

const Component = (props: Props) => {
  const bg = useColorModeValue('white', 'utils.dark.elevated.08dp')
  const [tenants, setTenants] = useState<Tenant[]>(TenantStore.getTenants())
  const [selectedTenant, setSelectedTenant] = useState<Tenant>()
  const { colorMode, toggleColorMode } = useColorMode()

  const { orderMode, selected, closeSideMenu, refresh } = props

  const setSelectedMenu = (menu: MenuNameDTO) => {
    MenuStore.setSelectedMenu(menu)
    if (closeSideMenu) {
      closeSideMenu()
    }
  }

  useEffect(() => {
    const tenant = tenants.find(x => x.id === getTenantId())
    if (tenant) {
      setSelectedTenant(tenant)
    }
  }, [tenants])

  useEffect(() => {
    setTenants(TenantStore.getTenants())
  }, [TenantStore.tenants])

  return (
        <>
            <Grid templateRows={'5rem 3fr 2fr'} boxShadow={'md'} bg={bg} color={'black'} padding={0}
                  justifyContent={'center'} w={'5rem'} h={{ base: '100%', xl: 'inherit' }}>
                {selectedTenant
                  ? <TenantPicker selectedTenant={selectedTenant} tenants={tenants} onSelect={(t) => {
                    setTenantId(t.id)
                    setSelectedTenant(t)
                    refresh()
                  }}/>
                  : <Image py={4} w={'100%'} px={1} src={'logo.svg'}/>

                }
                <VStack width={'100%'} overflowX={'hidden'} alignItems={'flex-start'} h={'100%'} w={'inherit'} gap={0}>
                    {orderMode
                      ? <>
                    {MenuStore.menuNameList.map((m, i) => (
                            <MenuItem key={i} name={m.name} menu={m} onClick={(m) => { setSelectedMenu(m) }} selected={m.name === MenuStore.selectedMenu.name}/>
                    ))}
                        </>
                      : <>
                            <NavigationItem icon={FaUtensils} name={'Menu'} selected={false} url={'/'}/>
                        </>
                    }
                  </VStack>
                  <VStack alignItems={'flex-end'} h={'100%'} w={'inherit'} gap={0} justifyContent={'flex-end'}>
                      <Center h={'8vh'} maxH={'5rem'} w={'inherit'}>
                          <IconButton onClick={toggleColorMode} isRound h={'70%'} w={'70%'}
                                      aria-label={'toggle dark-mode'} icon={colorMode === 'light' ? <FaMoon/> : <FaSun/>}/>
                      </Center>
                      <Divider/>
                      <AuthenticatedTemplate>
                          <NavigationItem selected={selected === 'orders'} url={'/deliveries'} name={'Bestellingen'} icon={FaClipboardList}/>
                          <NavigationItem selected={selected === 'edit'} url={'/editor'} name={'Beheer'} icon={FaPen}/>
                      </AuthenticatedTemplate>
                      <Divider/>
                      <LoginButton/>
                  </VStack>
          </Grid>
      </>
  )
}

export const SideMenu = withObserver(Component)
