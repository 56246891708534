import { apiCall, CallType } from '../shared/utils/api'
import { type Tenant } from '../shared/model'

const getTenants = async (): Promise<Tenant[]> => {
  return await apiCall(false, '/tenants', CallType.GET) ?? []
}

export const tenantsService = {
  getTenants
}
