import { apiCall, CallType } from '../shared/utils/api'
import { type UserOrder } from '../shared/model'
import { getTenantId } from '../shared/tenant/tenant'

const postOrder = async (order: UserOrder): Promise<void> => {
  return await apiCall(true, `/deliveries/order?tenant=${getTenantId()}`, CallType.POST, order)
}

export const orderService = {
  postOrder
}
