import { withObserver } from '../shared/utils/stores'
import { isMobile } from '../shared/utils/mobileCheck'
import { Header } from '../shared/layout/header/Header'
import { SideMenu } from '../shared/layout/side-menu/SideMenu'
import React from 'react'
import {
  AlertDialog, AlertDialogBody,
  AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,
  Box,
  Button,
  Center, Divider,
  Grid, Heading,
  HStack,
  Text,
  useColorModeValue, useToast, VStack
} from '@chakra-ui/react'
import { MenuStore } from '../shared/stores/menu-store'
import { MenuTitleCard } from './components/MenuTitleCard'
import { Form, Formik } from 'formik'
import { MenuOptionsCard } from './components/MenuOptionsCard'
import { FaInfoCircle, FaPlus } from 'react-icons/fa'
import { getScrollBar } from '../shared/utils/scrollbar'
import { MenuItemsCard } from './components/MenuItemsCard'
import { FaFloppyDisk, FaTrashCan } from 'react-icons/fa6'
import { MenuOverviewBar } from './components/MenuOverviewBar'
import { useMenuEditorState } from './menu-editor.state'
import { ReactSortable } from 'react-sortablejs'
import styles from '../styles/DragDrop.module.css'
import { MenuTenantCard } from './components/MenuTenantCard'

const Component = () => {
  const bg = useColorModeValue('utils.200', 'utils.bg.dark')
  const divider = useColorModeValue('utils.gray', 'utils.dark.elevated.16dp')
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const toast = useToast()

  const {
    openAlert,
    closeAlert,
    showAlert,
    updateVariation,
    addVariation,
    deleteVariation,
    addMenuItem,
    deleteMenuItem,
    updateMenuItem,
    addMenu,
    updateMenu,
    removeMenu,
    tenants,
    updateTenants,
    selectedMenu,
    allMenus,
    setSelectedMenu,
    updateOrder
  } = useMenuEditorState()

  return (
        <>
            <Grid h={'100vh'} maxH={'100vh'} bg={bg} templateColumns={{ base: '', lg: '5rem auto' }}
                  templateRows={{ base: '64px auto', lg: 'none' }}>
                {isMobile()
                  ? (
                        <>
                            <Header title={'Menu beheer'} selected={'edit'} orderMode={false} refresh={() => {
                              console.log('doet nog niks')
                            }}/>
                        </>)
                  : <SideMenu orderMode={false} selected={'edit'} refresh={() => {
                    console.log('doet nog niks')
                  }}/>
                }
                <Box overflowY={'auto'} css={getScrollBar()} w={'100%'}>
                    <MenuOverviewBar addNewMenu={addMenu} setSelectedMenu={(m) => {
                      setSelectedMenu(m)
                    }} menus={allMenus} selectedMenu={selectedMenu}/>
                    {selectedMenu.options &&
                        <Box p={4}>

                        <Formik
                            enableReinitialize
                            initialValues={{}}
                            onSubmit={async (data, { setSubmitting }) => {
                              setSubmitting(true)
                              setSelectedMenu({ ...selectedMenu, items: updateOrder(selectedMenu.items) })
                              await MenuStore.updateMenu(selectedMenu)
                              toast({
                                title: 'Menu geüpdatet',
                                description: 'Menu is met succes opgeslagen',
                                status: 'success',
                                duration: 9000,
                                isClosable: true
                              })
                              setSubmitting(false)
                            }}
                        >
                            {({ isSubmitting, handleSubmit }) => (
                                <Form
                                    onSubmit={handleSubmit}
                                >
                                    <Heading>{'Menu'}</Heading>
                                    <Divider my={2} borderColor={divider}/>
                                    <HStack h={'fit-content'}>
                                    <MenuTitleCard name={selectedMenu.name} image={selectedMenu.image}
                                                   updateMenu={updateMenu}/>
                                    <MenuTenantCard tenants={tenants} selectedTenants={selectedMenu.tenants} setTenants={updateTenants}/>
                                    </HStack>
                                    <HStack>
                                        <Heading pt={4}>{'Opties'}</Heading>
                                        <VStack alignItems={'start'} rowGap={0}>
                                            <HStack>
                                                <FaInfoCircle/>
                                                <Text>Sla nieuwe opties eerst op voordat deze worden toegevoegd aan een item.</Text>
                                            </HStack>
                                            <Text ps={6}>Een optie moet een unieke naam hebben.</Text>
                                        </VStack>
                                    </HStack>
                                    <Divider my={2} borderColor={divider}/>
                                    <Grid templateColumns={{ base: '1fr', '2xl': '1fr 1fr' }} w={'fit-content'} rowGap={8}
                                          columnGap={16}>
                                        {selectedMenu.options.map((x, i) => (
                                            <MenuOptionsCard updateVariation={updateVariation}
                                                             deleteVariation={deleteVariation} key={`${x.id}${i}`}
                                                             options={x}/>
                                        ))}
                                        <Center maxW={'50rem'}>
                                            <Button w={'80% '} aria-label={'add new variation'} rightIcon={<FaPlus/>}
                                                    onClick={addVariation} variant={'utilsButtonPrimaryOutline'}>Nieuwe
                                                optie</Button>
                                        </Center>
                                    </Grid>
                                    <Heading pt={4}>{'Items'}</Heading>
                                    <Divider my={2} borderColor={divider}/>
                                    <ReactSortable scroll forceFallback animation={150} className={styles.dragdrop} list={selectedMenu.items} setList={(e) => {
                                      setSelectedMenu({ ...selectedMenu, items: e })
                                    }}>
                                        {selectedMenu.items.map((x, i) => (
                                                    <MenuItemsCard key={`${x.id}${i}`} updateMenuItem={updateMenuItem}
                                                               deleteMenuItem={deleteMenuItem}
                                                                   menuItem={x} availableOptions={selectedMenu.options}/>
                                        ))}
                                    </ReactSortable>
                                    <Center maxW={'50rem'} pt={4}>
                                        <Button w={'80% '} aria-label={'add new variation'} rightIcon={<FaPlus/>}
                                                onClick={addMenuItem} variant={'utilsButtonPrimaryOutline'}>Nieuw
                                            item</Button>
                                    </Center>

                                    <HStack bottom={'1rem'} right={'1rem'} position={'fixed'}>
                                        <Button variant={'utilsButtonDelete'} isDisabled={isSubmitting} isLoading={MenuStore.isLoading} boxShadow={'md'} onClick={openAlert} h={'3rem'}
                                                leftIcon={<FaTrashCan/>}>Verwijderen</Button>
                                        <Button type={'submit'} h={'3rem'} isLoading={isSubmitting} isDisabled={MenuStore.isLoading} boxShadow={'md'} variant={'utilsButtonPrimary'}
                                                leftIcon={<FaFloppyDisk/>}>Opslaan</Button>
                                    </HStack>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                    }

                </Box>
            </Grid>
            <AlertDialog
                isOpen={showAlert}
                isCentered
                onClose={closeAlert} leastDestructiveRef={cancelRef}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Verwijder Menu
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Weet je zeker dat je het menu wilt verwijderen? Dit kan niet teruggedraaid worden.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={closeAlert}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                              removeMenu()
                            }} ml={3}>
                                Verwijderen
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
  )
}

export const MenuEditor = withObserver(Component)
