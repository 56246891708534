import { useLocalStore } from '.'
import { useEffect } from 'react'

export type StoreInitializer<TStore> = (() => TStore)

export const useStore = <TStore>(store: StoreInitializer<TStore>): TStore => {
  const localStore = useLocalStore<any>(() => store())

  useEffect(() => {
    if (localStore.onInitialize) {
      // eslint-disable-next-line @typescript-eslint/return-await
      const initializer = async () => await localStore.onInitialize()
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      initializer()
    }

    return localStore.onUnmount
  }, [localStore])

  return localStore
}
