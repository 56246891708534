import { proxyToJs, withObserver } from '../../shared/utils/stores'
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Text,
  Textarea,
  useColorModeValue,
  VStack
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { type MenuItem, type Variation } from '../../shared/model'
import React, { useEffect, useState } from 'react'
import { FaTrashCan } from 'react-icons/fa6'
import { FaGripVertical } from 'react-icons/fa'
import { ImageUploader } from '../../shared/components/ImageUploader'

interface Props {
  updateMenuItem: (menuItem: MenuItem, oldName: string) => void
  deleteMenuItem: (menuItem: MenuItem) => void
  menuItem: MenuItem
  availableOptions: Variation[]
}
const Component = (props: Props) => {
  // const { updateMenuItem, deleteMenuItem, menuItem, availableOptions, moveCard, index, id } = props
  const { updateMenuItem, deleteMenuItem, menuItem, availableOptions } = props
  const bg = useColorModeValue('white', 'utils.dark.elevated.12dp')
  const [updatedMenuItem, setUpdatedMenuItem] = useState(proxyToJs(menuItem))
  const [newPrice, setNewPrice] = useState(menuItem.price)
  const [priceInput, setPriceInput] = useState(`${menuItem.price}`)

  useEffect(() => {
    updateMenuItem(updatedMenuItem, menuItem.name)
  }, [updatedMenuItem])

  const getOptionsFromValue = (options: [{ value: string, label: string }]) => {
    const newOptionsList: Variation[] = []
    options.forEach(x => {
      const option = availableOptions.find(o => `${o.name};${o.id}` === x.value)
      if (option) {
        newOptionsList.push(option)
      }
    })
    return newOptionsList
  }

  return (
        // <Card bg={bg} w={'50rem'} style={{ opacity }} ref={ref} data-handler-id={handlerId}>
        <Card bg={bg} w={'50rem'} opacity={'1 !important'}>
            <CardHeader>
                <HStack justifyContent={'space-between'}>
                    <ImageUploader image={updatedMenuItem.image} updateImage={(x) => {
                      setUpdatedMenuItem({ ...updatedMenuItem, image: x })
                    }} isPNG/>
                    <FormControl>
                        <FormLabel>Naam</FormLabel>
                        <Input
                            w={'15rem'}
                            defaultValue={updatedMenuItem.name}
                            placeholder={'Item naam...'}
                            onBlur={(e) => {
                              setUpdatedMenuItem({ ...updatedMenuItem, name: e.target.value })
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Prijs</FormLabel>
                        <InputGroup w={'6rem'}>
                            <InputLeftElement>
                                <Text color={'utils.gray'}>{'€'}</Text>
                            </InputLeftElement>
                          <Input type={'number'} value={priceInput} onChange={(e) => {
                            setPriceInput(e.target.value)
                            setNewPrice(Number(e.target.value))
                          }} onBlur={() => {
                            setPriceInput(`${Number(priceInput)}`)
                            setUpdatedMenuItem({ ...updatedMenuItem, price: newPrice })
                          }}/>
                        </InputGroup>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Variabele prijs</FormLabel>
                        <Switch
                            onChange={(e) => {
                              setUpdatedMenuItem({ ...updatedMenuItem, variablePrice: e.target.checked })
                            }}
                            defaultChecked={updatedMenuItem.variablePrice}
                        />
                    </FormControl>
                    <VStack mb={4} gap={6}>
                        <FaGripVertical color={'utils.gray'} cursor={'move'}/>
                        <IconButton aria-label={'delete option'} onClick={() => {
                          deleteMenuItem(updatedMenuItem)
                        }} isRound variant={'utilsButtonDelete'}
                                    icon={<FaTrashCan/>}/>
                    </VStack>
                </HStack>
                <Divider mt={4}/>
            </CardHeader>
            <CardBody pt={0}>
                <FormControl>
                    <FormLabel>Omschrijving</FormLabel>
                    <Textarea
                        defaultValue={updatedMenuItem.description}
                        placeholder={'Item naam...'}
                        onBlur={(e) => {
                          setUpdatedMenuItem({ ...updatedMenuItem, description: e.target.value })
                        }}
                    />
                </FormControl>
                <FormControl pt={4}>
                    <FormLabel>Opties</FormLabel>
                    <Select isMulti useBasicStyles onChange={(e: any) => {
                      setUpdatedMenuItem({ ...updatedMenuItem, options: getOptionsFromValue(e) })
                    }} placeholder={'Opties...'} value={menuItem.options.map(x => {
                      return { label: `${x.name}`, value: `${x.name};${x.id}` }
                    })} options={availableOptions.map(x => {
                      return { label: `${x.name}`, value: `${x.name};${x.id}` }
                    })}/>
                </FormControl>
            </CardBody>
        </Card>
  )
}

export const MenuItemsCard = withObserver(Component)
