import {
  Avatar,
  Icon,
  Menu, MenuButton, MenuItem, MenuList, Text,
  useColorModeValue,
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { FaRightFromBracket, FaRightToBracket } from 'react-icons/fa6'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { loginRequest } from '../../../../authConfig'

export const LoginButton = () => {
  const iconColor = useColorModeValue('black', 'white')
  const selectedBg = useColorModeValue('utils.810', 'utils.dark.elevated.02dp')

  const { instance } = useMsal()

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => { console.log(error) })
  }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => { console.log(error) })
  }
  const activeAccount = instance.getActiveAccount()

  return (
        <VStack maxH={'5rem'} borderLeft={'none'} p={'1vh'} w={'inherit'} borderColor={'utils.900'} gap={0}
                justifyContent={'center'} _hover={{ bg: selectedBg, cursor: 'pointer' }}>
            <UnauthenticatedTemplate>
                <Icon as={FaRightToBracket} w={'70%'} h={'3vh'} pb={1}
                      color={iconColor} onClick={handleLoginRedirect}/>
                <Text fontSize={'2xs'} fontWeight={500} color={iconColor}>{'Inloggen'}</Text>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Menu variant={'utilsMenu'}>
                    <MenuButton>
                        <Avatar name={activeAccount?.name}/>
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={handleLogoutRedirect} icon={<FaRightFromBracket/>}>Afmelden</MenuItem>
                    </MenuList>
                </Menu>
            </AuthenticatedTemplate>
        </VStack>
  )
}
