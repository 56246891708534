import React from 'react'
import { Skeleton, useColorModeValue } from '@chakra-ui/react'

interface Props {
  amount: number
}

export const FoodCardsMockLoading = (props: Props) => {
  const { amount } = props
  const bgStart = useColorModeValue('uitls.gray', 'utils.gray')
  const bgEnd = useColorModeValue('utils.250', 'black')

  return (
        <>
            {[...Array(amount)].map((x, i) =>
                    <Skeleton key={i} borderRadius={8} startColor={bgStart} endColor={bgEnd} mt={3} height={'7rem'}/>
            )}
        </>
  )
}
