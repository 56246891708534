import {
  Badge,
  Card,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Text,
  Tooltip,
  useColorModeValue,
  VStack
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FoodModal } from './FoodModal'
import { type MenuItem, type UserOrder } from '../../shared/model'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { NoImagePlaceholder } from '../../shared/components/NoImagePlaceholder'
import { FavoriteIcon } from '../../shared/components/FavoriteIcon'
import { MenuStore } from '../../shared/stores/menu-store'
import { isMobile } from '../../shared/utils/mobileCheck'

interface Props {
  menuItem: MenuItem
  postOrder: (order: UserOrder) => void
  favorite: boolean
  deliveryDate: Date
}

export const FoodCard = (props: Props) => {
  const { menuItem, postOrder, favorite, deliveryDate } = props
  const [isHover, setHover] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const primaryColor = useColorModeValue('#E7F0D3', '#7BB801')
  const primaryHover = useColorModeValue('#d4eab2', '#547e00')
  const secondaryColor = useColorModeValue('#7BB801', 'white')
  const secondaryHover = useColorModeValue('#ffffff', '#dadada')
  const bg = useColorModeValue('white', 'utils.dark.elevated.12dp')
  const descriptionText = useColorModeValue('', '#bebebe')
  const mobileCheck = isMobile()
  const closeModal = () => {
    setModalOpen(false)
  }

  return (
        <>
            <Card
                borderWidth="1px"
                borderRadius={'lg'}
                w={{ base: '100%' }}
                h={'7rem'}
                bg={bg}
                overflow="hidden"
                boxShadow="md"
                transitionDuration={'0.3s'}
                transitionTimingFunction={'ease-in-out'}
                borderColor={bg}
                onClick={() => {
                  setModalOpen(true)
                }}
                onMouseEnter={() => {
                  setHover(true)
                }}
                onMouseLeave={() => {
                  setHover(false)
                }}
                _hover={{
                  backgroundColor: 'utils.dark.elevated.24dp',
                  transitionDuration: '0.3s',
                  transitionTimingFunction: 'ease-in-out',
                  cursor: 'pointer'
                }}
            >
                <Grid
                    templateColumns={'7rem 1fr 1fr 1fr 1fr'}
                    h={'inherit'}

                >
                    <GridItem h={'inherit'} w={'100%'}>
                        {menuItem.image !== '' && menuItem.image
                          ? <Image objectFit={'cover'} w={'inherit'} h={'inherit'} src={`data:image/jpeg;base64,${menuItem.image}`} alt={menuItem.name}/>
                          : <NoImagePlaceholder/>
                        }
                    </GridItem>
                    <GridItem colSpan={3} pl={2}>
                        <VStack h={'100%'} alignItems={'start'} justifyContent={'center'} gap={0}>
                                <Heading fontSize={{ base: 'md', '2xl': 'lg' }} noOfLines={2}>
                                    {menuItem.name}
                                </Heading>

                            <Text fontSize={{ base: 'xs', '2xl': 'sm' }} color={descriptionText} noOfLines={2}>
                                {menuItem.description}
                            </Text>
                            <HStack>
                                <Text fontSize={{ base: 'md', '2xl': 'lg' }} fontWeight={500}>
                                    {`€${menuItem.price.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
                                </Text>
                                {menuItem.variablePrice &&
                                    <Tooltip placement={'top'} label={'Deze prijs kan nog veranderen bij betalen.'}>
                                        <Badge>Variabel</Badge>
                                    </Tooltip>
                                }
                            </HStack>
                        </VStack>
                    </GridItem>
                    <AuthenticatedTemplate>
                        <VStack h={'inherit'} justifyContent={((!favorite && isHover) || favorite || mobileCheck) ? 'space-between' : 'end'} justifySelf={'end'}>
                            {((!favorite && isHover) || favorite || mobileCheck) &&
                            <FavoriteIcon favorite={favorite} updateFavorite={(isFavorite) => { MenuStore.updateFavoriteMenuItems(isFavorite, menuItem) }}/>
                            }
                            <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M57 40L0 40C5.78616 21.4359 21.1777 7.46503 40.2137 3.49811L57 3.8147e-06L57 40Z"
                                      fill={isHover ? primaryHover : primaryColor}/>
                                <path
                                    d="M29.8236 21.8199C29.8236 21.4172 29.4983 21.0919 29.0956 21.0919C28.693 21.0919 28.3677 21.4172 28.3677 21.8199V25.0956H25.092C24.6893 25.0956 24.364 25.4209 24.364 25.8235C24.364 26.2262 24.6893 26.5515 25.092 26.5515H28.3677V29.8272C28.3677 30.2299 28.693 30.5552 29.0956 30.5552C29.4983 30.5552 29.8236 30.2299 29.8236 29.8272V26.5515H33.0993C33.502 26.5515 33.8272 26.2262 33.8272 25.8235C33.8272 25.4209 33.502 25.0956 33.0993 25.0956H29.8236V21.8199Z"
                                    fill={isHover ? secondaryHover : secondaryColor}
                                />
                            </svg>
                        </VStack>
                    </AuthenticatedTemplate>

                </Grid>
            </Card>
            <FoodModal menuItem={menuItem} modalOpen={modalOpen} deliveryDate={deliveryDate}
                       closeModal={closeModal} postOrder={postOrder}/>
        </>
  )
}
