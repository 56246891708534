import { asGlobalStore } from '../utils/stores'
import { msalInstance } from '../../index'

const createStore = () => ({
  async performAuthCheck () {
    if (!msalInstance.getActiveAccount()) {
      await msalInstance.loginRedirect()
    }
  },
  async getToken () {
    let bearer = ''
    const request = {
      scopes: [],
      roles: ['USER.ORDER']
    }
    await msalInstance.acquireTokenSilent(request).then(tokenResponse => {
      bearer = tokenResponse.idToken
    }).catch(e => {
      console.log(e)
      AuthStore.login()
    })
    return bearer
  },
  async login () {
    await msalInstance.loginRedirect()
  },
  async logout () {
    await msalInstance.logoutRedirect()
  }
})
export const AuthStore = asGlobalStore(createStore())
