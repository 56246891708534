import React, { useRef } from 'react'
import { Box, Center, Image, Input, VStack, Text, Icon, useColorModeValue } from '@chakra-ui/react'
import { FaUpload } from 'react-icons/fa'
import Compressor from 'compressorjs'

interface Props {
  image: string
  isSVG?: boolean
  isPNG?: boolean
  updateImage: (image: string) => void
}

export const ImageUploader = (props: Props) => {
  const { updateImage, image, isSVG, isPNG } = props
  const hiddenFileInput = useRef(null)
  const border = useColorModeValue('utils.gray', 'utils.dark.elevated.12dp')
  const handleClick = () => {
    if (hiddenFileInput.current) {
      // @ts-expect-error
      hiddenFileInput.current.click()
    }
  }

  const handleUpload = (file: File) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.6,
      success: (compressedResult) => {
        const fileReader = new FileReader()
        if (file.type === 'image/svg+xml') {
          fileReader.readAsDataURL(file)
        } else {
          fileReader.readAsDataURL(compressedResult)
        }
        fileReader.onload = () => {
          const result = fileReader.result as string
          const base64 = result.split(',')[1]
          updateImage(base64)
        }
      }
    })
  }

  return (
      <Box>
          <Box cursor={'pointer'} w={'100px'} h={'100px'} onClick={handleClick} position={'relative'} display={'inline-block'}>
              {image === '' || !image
                ? <Center _hover={{ bg: '#2727272e', color: 'white' }} border={border} borderWidth={1} borderRadius={8} w={'100px'} aspectRatio={'1/1'}>
                      <VStack>
                          <Icon as={FaUpload}/>
                          <Text>Upload</Text>
                      </VStack>
                  </Center>
                : <>
                  <Image borderRadius={8} w={'inherit'} h={'inherit'} bg={'white'} objectFit={isSVG ? 'contain' : 'cover'} src={image ? isSVG ? `data:image/svg+xml;base64,${image}` : isPNG ? `data:image/jpeg;base64,${image}` : '' : ''}/>
              <Center _hover={{ bg: '#2727272e', color: 'white' }} color={'transparent'} borderRadius={8} position={'absolute'} top={'0px'} right={'0px'} w={'100px'} aspectRatio={'1/1'}>
                  <VStack>
                      <Icon as={FaUpload}/>
                        <Text>Upload</Text>
                  </VStack>
              </Center>
                  </>
          }
          </Box>
          <Input
              type="file"
              display={'none'}
              name="myImage"
              accept={isSVG ? '.svg' : isPNG ? 'image/png, image/jpeg' : 'image/*'}
              ref={hiddenFileInput}
              onChange={(event) => {
                if (event.target.files?.[0]) {
                  handleUpload(event.target.files[0])
                }
              }}
          />
      </Box>
  )
}
