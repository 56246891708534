export const getTenantId = () => {
  const tenantId = window.localStorage.getItem('tenant')
  if (tenantId) {
    return Number(tenantId)
  }
  return 0
}

export const setTenantId = (id: number) => {
  window.localStorage.setItem('tenant', String(id))
}
