import { Badge, Box, Button, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { type Delivery } from '../../../../shared/model'
import { getSumPriceFromDelivery, getTotalAmountFromDelivery } from '../../../../shared/utils/delivery'

interface Props {
  showOrdersToday: () => void
  delivery?: Delivery
}

const Component = (props: Props) => {
  const { delivery, showOrdersToday } = props
  const bgHeader = useColorModeValue('white', 'utils.dark.elevated.02dp')

  return (
        <Flex justifyContent={'space-between'} alignItems={'center'} px={4} bg={bgHeader} h={'100%'} w={'100%'} boxShadow={'0px -2px 4px 0px rgba(0, 0, 0, 0.25)'}>
            <HStack>
          <Text
              fontWeight={500}>{`Totaal: ${delivery?.sumPriceOrders ? `€${delivery.sumPriceOrders.toLocaleString(undefined, { minimumFractionDigits: 2 })}` : getSumPriceFromDelivery(delivery)} `}
          </Text>
            {delivery?.usernamePayer && delivery.usernamePayer !== ''
              ? <Badge colorScheme='green'>betaald</Badge>
              : <Badge>nog betalen</Badge>
            }
            </HStack>
            <Box position={'relative'} display={'inline-block'}>
                <Button aria-label={'open orders today'} onClick={showOrdersToday}
                        boxShadow={'md'}
                            variant={'utilsButtonPrimary'}>Overzicht</Button>
                <Box position={'absolute'} top={'-7px'} right={'-7px'} padding={'2px 10px'}
                     borderRadius={'50%'} bg={'red.600'} color={'white'}>{getTotalAmountFromDelivery(delivery)}</Box>
            </Box>
        </Flex>
  )
}

export const OrdersTodayBar = Component
