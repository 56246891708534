import { format } from 'date-fns'

export const getTodayDate = (): string => {
  const today = new Date(Date.now())
  return `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`
}

export const formatDate = (date: Date): string => {
  return format(date, 'yyyy-MM-dd')
}

export const formatBackEndDate = (date: Date): string => {
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
}

export const isDateInpast = (date: Date): boolean => {
  const checkDate = new Date(formatDate(date))
  const today = new Date(formatDate(new Date(Date.now())))
  return (checkDate.getTime() < today.getTime())
}
