import { useStore, withObserver } from '../shared/utils/stores'
import { isMobile } from '../shared/utils/mobileCheck'
import { Header } from '../shared/layout/header/Header'
import { SideMenu } from '../shared/layout/side-menu/SideMenu'
import React, { useState } from 'react'
import {
  Badge,
  Center,
  Grid, Spinner, Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue, useToast
} from '@chakra-ui/react'
import { getSumPriceFromDelivery, getTotalAmountFromDelivery } from '../shared/utils/delivery'
import { DeliveryStore } from '../shared/stores/delivery-store'
import { OverviewModal } from '../menu/components/orders-today/components/OverviewModal'
import { type Delivery } from '../shared/model'
import { getScrollBar } from '../shared/utils/scrollbar'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { LoginButton } from '../shared/layout/side-menu/components/LoginButton'
import { deliveryService } from '../services/delivery-service'

const Component = () => {
  const bg = useColorModeValue('utils.200', 'utils.bg.dark')
  const hover = useColorModeValue('utils.50', 'utils.dark.elevated.06dp')
  const bgTable = useColorModeValue('white', 'utils.bg.dark')
  const border = useColorModeValue('utils.40', 'utils.dark.elevated.12dp')
  const store = useStore(DeliveryStore)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery>()
  const toast = useToast()

  const closeModal = () => {
    setModalOpen(false)
  }

  const openModal = (delivery: Delivery) => {
    setSelectedDelivery(delivery)
    setModalOpen(true)
  }

  return (
        <>
            <Grid h={'100vh'} maxH={'100vh'} bg={bg} templateColumns={{ base: '', lg: '5rem auto' }}
                  templateRows={{ base: '64px auto', lg: 'none' }}>
                {isMobile()
                  ? (
                        <>
                            <Header title={'Bestellingen'} selected={'orders'} orderMode={false} refresh={() => {
                              console.log('doet nog niks')
                            }}/>
                        </>)
                  : <SideMenu orderMode={false} selected={'orders'} refresh={() => {
                    console.log('doet nog niks')
                  }}/>
                }
                <AuthenticatedTemplate>
                <Center w={{ base: '100vw', lg: 'inherit' }} h={'inherit'}>
                    {store.getDeliveries().length > 0
                      ? <TableContainer borderColor={border} borderWidth={1} borderRadius={8} bg={bgTable} overflowY={'auto'} boxShadow={'md'} maxH={{ base: '100%', lg: '80%' }} w={{ base: '100%', lg: '' }} maxW={{ base: '', lg: '80%' }} css={getScrollBar()}>
                    <Table variant='striped'>
                        <Thead>
                            <Tr>
                                <Th>Datum</Th>
                                <Th>Aantal items</Th>
                                <Th>Betaler</Th>
                                <Th>Totaal</Th>
                                <Th>Betaald</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {store.getDeliveries().map((d, index) => (
                                <Tr key={index} onClick={() => { openModal(d) }} _hover={{ bg: hover, cursor: 'pointer' }}>
                                    <Td>{d.date}</Td>
                                    <Td>{getTotalAmountFromDelivery(d)}</Td>
                                    <Td>{d.usernamePayer !== '' ? d.usernamePayer : '-'}</Td>
                                    <Td>{getSumPriceFromDelivery(d)}</Td>
                                    <Td> {d.usernamePayer !== ''
                                      ? <Badge colorScheme='green'>betaald</Badge>
                                      : <Badge>nog betalen</Badge>
                                    }</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                      : <Spinner/>
                    }
                </Center>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Stack justifyContent={'center'} w={'inherit'} h={{ base: '100%', lg: 'inherit' }} alignContent={'center'}>
                        <Text align={'center'}>
                            {'Login om de bezorgingen te bekijken'}
                        </Text>
                        <LoginButton/>
                    </Stack>
                </UnauthenticatedTemplate>
            </Grid>
            {selectedDelivery &&
            <OverviewModal modalOpen={modalOpen} closeModal={closeModal} orders={selectedDelivery} payDelivery={async (d) => {
              await deliveryService.payDelivery(d)
              toast({
                title: `${d.paymentURL ? 'Betaling gelukt' : 'Bestelling gelukt'}`,
                description: `${d.paymentURL ? 'Mail is succesvol verstuurd' : 'Gratis bestelling is verwerkt'}`,
                status: 'success',
                duration: 9000,
                isClosable: true
              })
              await store.refreshDeliveries()
            }}/>
            }

        </>
  )
}

export const DeliveryList = withObserver(Component)
