import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'react-datepicker/dist/react-datepicker.css'
import { PublicClientApplication, EventType, type AuthenticationResult } from '@azure/msal-browser'
import { msalConfig } from './authConfig'
import { createStandaloneToast } from '@chakra-ui/react'

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback(async (event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
    if (account.idToken) {
      sessionStorage.setItem('bearer', account.idToken)
    }
  }
})

async function boot () {
  await msalInstance.initialize()
  const { ToastContainer } = createStandaloneToast()
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  )
  root.render(
        // <React.StrictMode>
        <>
            <App pca={msalInstance}/>
            <ToastContainer/>
        </>

    // </React.StrictMode>
  )
}

boot()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
