import { type Tenant } from '../../../model'
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack
} from '@chakra-ui/react'
import React, { useState } from 'react'

interface Props {
  selectedTenant: Tenant
  tenants: Tenant[]
  onSelect: (tenant: Tenant) => void
}

export const TenantPicker = (props: Props) => {
  const { selectedTenant, tenants, onSelect } = props
  const [openPicker, setOpenPicker] = useState(false)
  const bg = useColorModeValue('white', 'utils.bg.dark')
  const selectedBg = useColorModeValue('utils.810', 'utils.dark.elevated.02dp')

  const openTenantPicker = () => {
    setOpenPicker(true)
  }

  const closeTenantPicker = () => {
    setOpenPicker(false)
  }

  return (
        <>
      <Image _hover={{ backgroundColor: selectedBg }} cursor={'pointer'} onClick={openTenantPicker} py={4} w={'100%'} px={1} src={`data:image/svg+xml;base64,${selectedTenant.image}`}/>

      <Drawer isOpen={openPicker} placement='left' onClose={closeTenantPicker}>
          <DrawerOverlay/>
          <DrawerContent bg={bg}>
              <DrawerCloseButton/>
              <DrawerBody pt={8}>
                  <Heading fontSize={24} pb={4}>Selecteer Organisatie</Heading>
                  {tenants.map(t => (
                      <VStack key={t.id}>
                      <HStack onClick={() => {
                        onSelect(t)
                        closeTenantPicker()
                      }} cursor={'pointer'} bg={selectedTenant.id === t.id ? selectedBg : ''} w={'100%'} _hover={{ backgroundColor: selectedBg }}>
                          <Image py={4} px={1} src={`data:image/svg+xml;base64,${t.image}`}/>
                          <Text fontSize={16}>{t.name}</Text>
                      </HStack>
                          <Divider/>
                      </VStack>
                  ))}
              </DrawerBody>
          </DrawerContent>
      </Drawer>
        </>

  )
}
