import { withObserver } from '../../shared/utils/stores'
import React, { useState } from 'react'
import {
  Box,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup, InputRightElement,
  Text,
  useColorMode,
  useColorModeValue,
  VStack
} from '@chakra-ui/react'
import { type Menu } from '../../shared/model'
import { FaPlus } from 'react-icons/fa'

interface Props {
  menus: Menu[]
  selectedMenu: Menu
  setSelectedMenu: (menu: Menu) => void
  addNewMenu: (menuName: string) => void
}

const Component = (props: Props) => {
  const { menus, selectedMenu, setSelectedMenu, addNewMenu } = props
  const [menuName, setMenuName] = useState<string>('')
  const bg = useColorModeValue('white', 'utils.dark.elevated.08dp')
  const selectedBg = useColorModeValue('utils.810', 'utils.dark.elevated.02dp')
  const { colorMode } = useColorMode()

  return (
        <HStack px={4} bg={bg} boxShadow={'md'} h={'5rem'}>
            {menus.map((x, i) => {
              const selected = x.name === selectedMenu.name
              return (
                <VStack pt={2} _hover={{ cursor: 'pointer', bg: selectedBg }} h={'inherit'} justifyContent={'space-between'} minW={'5rem'} gap={1} w={'fit-content'} onClick={() => { setSelectedMenu(x) }} bg={selected ? selectedBg : ''} key={`${x.name}${i}`}>
                    <Image h={'3vh'} src={`data:image/svg+xml;base64,${x.image}`} filter={colorMode === 'light' ? '' : 'invert(99%) sepia(99%) saturate(0%) hue-rotate(217deg) brightness(105%) contrast(100%)'}/>
                    <Text>{x.name}</Text>
                  <Box bg={selected ? 'utils.900' : 'transparent'} h={1} w={'100%'}/>
                </VStack>
              )
            })}
          <InputGroup w={'15rem'}>
            <InputRightElement>
              <IconButton isDisabled={menuName.length <= 0} isRound aspectRatio={'1/1'} aria-label={'add new menu'} size={'sm'} icon={<FaPlus/>} onClick={() => {
                addNewMenu(menuName)
                setMenuName('')
              }} variant={'utilsButtonPrimary'}/>
            </InputRightElement>
            <Input
                placeholder={'Nieuw menu toevoegen...'}
                value={menuName}
                onChange={(e) => { setMenuName(e.target.value) }}
            />
          </InputGroup>
        </HStack>

  )
}

export const MenuOverviewBar = withObserver(Component)
