import { Card, FormControl, FormLabel, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { ImageUploader } from '../../shared/components/ImageUploader'

interface Props {
  name: string
  image: string
  updateMenu: (name: string, image: string) => void
}

export const MenuTitleCard = (props: Props) => {
  const { name, image, updateMenu } = props
  const bg = useColorModeValue('white', 'utils.dark.elevated.12dp')
  const infoText = useColorModeValue('utils.gray', 'white')

  const updateImage = (image: string) => {
    updateMenu(name, image)
  }

  return (
        <Card bg={bg} w={'50rem'} p={4}>
            <HStack gap={4}>
                <ImageUploader image={image} isSVG updateImage={updateImage}/>
                <FormControl>
                    <FormLabel>Menu naam</FormLabel>
                    <Text>{name}</Text>
                </FormControl>
            </HStack>
            <HStack pt={2}>
                <Icon color={infoText} as={FaInfoCircle} flexShrink={0}/>
                <Text as={'i'} color={infoText}>
                    {'Voeg een SVG afbeelding toe van 24x24 \n' +
                        'de afbeelding moet één kleur zijn met de HEX-Code: #2a2e31ff '}
                </Text>
            </HStack>
        </Card>
  )
}
