import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  list: {
    // this will style the MenuList component
    _dark: {
      bg: 'utils.bg.dark'
    }
  },
  item: {
    // this will style the MenuItem and MenuItemOption components
    _dark: {
      bg: 'utils.bg.dark',
      color: 'gray.200',
      _hover: {
        bg: 'utils.dark.elevated.01dp'
      }
    }
  }
})
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle })
