import { apiCall, CallType } from '../shared/utils/api'
import { type Menu, type MenuItem, type MenuNameDTO } from '../shared/model'
import { getTenantId } from '../shared/tenant/tenant'

const getMenus = async (): Promise<Menu[]> => {
  return await apiCall(false, `/menus/all?tenant=${getTenantId()}`, CallType.GET) ?? []
}

const getAllMenus = async (): Promise<Menu[]> => {
  return await apiCall(false, '/menus/all', CallType.GET) ?? []
}

const getMenu = async (menuName: string): Promise<Menu> => {
  return await apiCall(false, `/menus/${menuName}`, CallType.GET) ?? {}
}

const getMenuNames = async (): Promise<MenuNameDTO[]> => {
  return await apiCall(false, `/menus/all/names?tenant=${getTenantId()}`, CallType.GET) ?? []
}

const addMenu = async (menu: Menu): Promise<void> => {
  return await apiCall(true, '/menus/add', CallType.POST, menu)
}

const updateMenu = async (menu: Menu): Promise<void> => {
  return await apiCall(true, '/menus/update', CallType.PUT, menu)
}

const deleteMenu = async (menuName: string): Promise<void> => {
  return await apiCall(true, `/menus/${menuName}`, CallType.DELETE)
}

const addFavoriteMenuItem = async (id: number): Promise<void> => {
  return await apiCall(true, `/menus/favorite/${id}`, CallType.POST)
}

const deleteFavoriteMenuItem = async (id: number): Promise<void> => {
  return await apiCall(true, `/menus/favorite/${id}`, CallType.DELETE)
}

const getFavoriteMenuItems = async (menuName: string): Promise<MenuItem[]> => {
  return await apiCall(true, `/menus/${menuName}/favorite`, CallType.GET)
}

export const menusService = {
  getMenus,
  getAllMenus,
  getMenu,
  getMenuNames,
  addMenu,
  updateMenu,
  deleteMenu,
  addFavoriteMenuItem,
  deleteFavoriteMenuItem,
  getFavoriteMenuItems
}
