import { type UserDelivery } from '../../../../shared/model'
import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Spinner,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { PersonalOrderCard } from './PersonalOrderCard'
import React, { useEffect } from 'react'
import { withObserver } from '../../../../shared/utils/stores'
import { getScrollBar } from '../../../../shared/utils/scrollbar'

interface Props {
  personalOrders: UserDelivery
  deletePersonalOrder: (deliveryId: number, orderId: number) => void
  isLoading: boolean
}

const Component = (props: Props) => {
  const { personalOrders, deletePersonalOrder, isLoading } = props
  const border = useColorModeValue('utils.200', 'utils.dark.elevated.12dp')

  const handleDelete = (orderId: number) => {
    deletePersonalOrder(personalOrders.deliveryId, orderId)
  }

  useEffect(() => {

  })

  return (
        <>
            <Card bg={'none'} overflow={'hidden'} boxShadow={'none'} borderColor={border} borderWidth={1}>
                <CardHeader>
                    <Text fontWeight={'medium'}>{'Jouw Bestelling'}</Text>
                    <Divider mt={2}/>
                </CardHeader>
                <CardBody py={0} px={4} mb={4} overflow={'auto'} css={getScrollBar()}>
                    {isLoading
                      ? <Center>
                            <Spinner/>
                        </Center>
                      : <Stack spacing={2}>
                        {personalOrders.orders && personalOrders.orders.length > 0
                          ? <>
                                {personalOrders.orders.map((o, index) => (
                                    <PersonalOrderCard key={index} handleDelete={handleDelete} order={o}/>
                                ))}
                            </>
                          : <Text color={'utils.250'}>Nog geen bestelling</Text>}
                    </Stack>
                    }
                </CardBody>
            </Card>
        </>
  )
}

export const PersonalOrderList = withObserver(Component)
