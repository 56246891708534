import {
  defineStyle,
  defineStyleConfig,
  extendTheme,
  createMultiStyleConfigHelpers
} from '@chakra-ui/react'
import { inputAnatomy } from '@chakra-ui/anatomy'
import { menuTheme } from './menu-theme'
import { switchTheme } from './switch-theme'

export const useTheme = () => {
  const { definePartsStyle, defineMultiStyleConfig } =
      createMultiStyleConfigHelpers(inputAnatomy.keys)

  const utilsButtonPrimary = defineStyle({
    background: 'utils.900',
    color: 'white',
    borderRadius: '2xl',
    paddingX: '8',
    _hover: {
      background: 'utils.950'
    },
    _active: {
      background: 'utils.960'
    }
  })

  const utilsButtonPrimaryOutline = defineStyle({
    background: 'white',
    color: 'utils.900',
    borderWidth: '1px',
    borderColor: 'utils.900',
    borderRadius: 'lg',
    paddingX: '8',
    _hover: {
      borderColor: 'utils.950',
      color: 'utils.950',
      background: 'utils.800',
      transform: 'scale(1.05, 1.05)'
    },
    _active: {
      background: 'utils.850',
      transform: 'scale(1, 1)'
    },
    _dark: {
      background: 'transparent'
    }
  })

  const utilsButtonSecondary = defineStyle({
    background: 'utils.800',
    color: 'utils.900',
    borderRadius: 'lg',
    py: '2',
    _hover: {
      background: 'utils.850',
      color: 'utils.950',
      transform: 'scale(1.05, 1.05)'
    },
    _active: {
      background: 'utils.860',
      transform: 'scale(1, 1)'
    },
    _disabled: {
      background: 'utils.810',
      color: 'utils.910',
      _hover: {
        background: 'utils.810',
        color: 'utils.910'
      }
    }
  })

  const utilsButtonDelete = defineStyle({
    background: 'utils.600',
    borderRadius: '2xl',
    color: 'utils.700',
    _hover: {
      background: 'utils.650',
      color: 'utils.750',
      transform: 'scale(1.05, 1.05)'
    },
    _active: {
      background: 'utils.660',
      color: 'utils.760',
      transform: 'scale(1, 1)'
    },
    _disabled: {
      background: 'utils.610',
      color: 'utils.710',
      _hover: {
        background: 'utils.610',
        color: 'utils.710'
      }
    },
    _dark: {
      background: 'utils.700',
      color: 'utils.600',
      _hover: {
        background: 'utils.750',
        color: 'utils.650',
        transform: 'scale(1.05, 1.05)'
      },
      _active: {
        background: 'utils.760',
        color: 'utils.660',
        transform: 'scale(1, 1)'
      },
      _disabled: {
        background: 'utils.710',
        color: 'utils.610',
        _hover: {
          background: 'utils.710',
          color: 'utils.610'
        }
      }
    }
  })

  const buttonTheme = defineStyleConfig({
    variants: { utilsButtonPrimary, utilsButtonSecondary, utilsButtonDelete, utilsButtonPrimaryOutline }
  })

  const utilsInput = definePartsStyle(() => {
    return {
      field: {
        border: '1px',
        bg: 'white',
        borderColor: 'utils.200',
        _hover: {
          border: '1px',
          borderColor: 'utils.250'
        },
        _focus: {
          border: '1px',
          borderColor: 'utils.250',
          boxShadow: '0 0 0 1px var(--chakra-colors-brand-200)'
        },
        _invalid: {
          border: '1px',
          borderColor: 'brand.300',
          boxShadow: '0 0 0 1px var(--chakra-colors-brand-300)'
        },
        _dark: {
          bg: 'utils.dark.elevated.12dp',
          border: '1px',
          borderColor: 'utils.dark.elevated.12dp',
          _hover: {
            border: '1px',
            borderColor: 'utils.250'
          },
          _focus: {
            border: '1px',
            borderColor: 'utils.250'
          }
        }
      }
    }
  })

  const inputTheme = defineMultiStyleConfig({
    variants: { utilsInput }
  })

  const utilsTextarea = defineStyle({
    border: '1px',
    bg: 'white',
    borderColor: 'utils.200',
    height: '10rem',
    resize: 'none',
    _hover: {
      border: '1px',
      borderColor: 'utils.250'
    },
    _focus: {
      border: '1px',
      borderColor: 'utils.250',
      boxShadow: '0 0 0 1px var(--chakra-colors-utils-200)'
    },
    _invalid: {
      border: '1px',
      borderColor: 'utils.300',
      boxShadow: '0 0 0 1px var(--chakra-colors-utils-300)'
    },
    _dark: {
      bg: 'utils.dark.elevated.12dp',
      border: '1px',
      borderColor: 'utils.dark.elevated.12dp',
      _hover: {
        border: '1px',
        borderColor: 'utils.dark.elevated.24dp'
      },
      _focus: {
        border: '1px',
        borderColor: 'utils.dark.elevated.12dp'
      }
    }
  })

  const textAreaTheme = defineStyleConfig({
    variants: { utilsTextarea }
  })

  const utilsSelect = definePartsStyle(() => {
    return {
      field: {
        border: '1px',
        bg: 'white',
        borderColor: 'utils.200',
        _hover: {
          border: '1px',
          borderColor: 'utils.250'
        },
        _focus: {
          border: '1px',
          borderColor: 'utils.250',
          boxShadow: '0 0 0 1px var(--chakra-colors-brand-200)'
        },
        _invalid: {
          border: '1px',
          borderColor: 'brand.300',
          boxShadow: '0 0 0 1px var(--chakra-colors-brand-300)'
        },
        _dark: {
          bg: 'utils.dark.elevated.12dp',
          border: '1px',
          borderColor: 'utils.dark.elevated.12dp',
          _hover: {
            border: '1px',
            borderColor: 'utils.250'
          },
          _focus: {
            border: '1px',
            borderColor: 'utils.250'
          }
        }
      }
    }
  })

  const selectTheme = defineMultiStyleConfig({
    variants: { utilsSelect }
  })

  const config = {
    initialColorMode: 'system',
    useSystemColorMode: false
  }

  /**
   * 900: Primary color
   * 910: Primary color disabled
   * 950: Primary color hover
   * 960: Primary color active
   * Elevated colors for dark mode are based on the elevation principle from Material design: https://m2.material.io/design/color/dark-theme.html#properties
   */
  // BG: #1b1f23 <- mogelijkheid
  const theme = extendTheme({
    colors: {
      'utils.960': '#609000',
      'utils.950': '#6A9F00',
      'utils.910': '#C2DE8B',
      'utils.900': '#7BB801',
      'utils.860': '#ccea70',
      'utils.850': '#D6EDA5',
      'utils.810': '#F2F7E8',
      'utils.800': '#E7F0D3',
      'utils.760': '#961422',
      'utils.750': '#BF1E2D',
      'utils.710': '#E9B5B5',
      'utils.700': '#CC4B4B',
      'utils.660': '#e58c8c',
      'utils.650': '#EEAAAA',
      'utils.610': '#F7ECEC',
      'utils.600': '#F0D3D3',
      'utils.250': '#B4B4B3',
      'utils.200': '#E8E7E6',
      'utils.100': '#EAEAEA',
      'utils.50': '#f5f5f5',
      'utils.40': '#F1F1F1',
      'utils.gray': '#595959',
      'utils.bg.dark': '#1b1f23',
      'utils.dark.elevated.01dp': 'rgba(255,255,255,0.05)',
      'utils.dark.elevated.02dp': 'rgba(255,255,255,0.07)',
      'utils.dark.elevated.03dp': 'rgba(255,255,255,0.08)',
      'utils.dark.elevated.04dp': 'rgba(255,255,255,0.09)',
      'utils.dark.elevated.06dp': 'rgba(255,255,255,0.11)',
      'utils.dark.elevated.08dp': 'rgba(255,255,255,0.12)',
      'utils.dark.elevated.12dp': 'rgba(255,255,255,0.14)',
      'utils.dark.elevated.16dp': 'rgba(255,255,255,0.15)',
      'utils.dark.elevated.24dp': 'rgba(255,255,255,0.16)',
      black: '#25292C',
      white: '#FCFCFC'
    },
    components: {
      Textarea: textAreaTheme,
      Select: selectTheme,
      Input: inputTheme,
      Button: buttonTheme,
      Menu: menuTheme,
      Switch: switchTheme
    },
    config
  })
  return theme
}
