import { Center, useColorModeValue } from '@chakra-ui/react'
import { FaImage } from 'react-icons/fa'
import React from 'react'

interface Props {
  width?: number
  borderRadius?: boolean
}

export const NoImagePlaceholder = (props: Props) => {
  const { width, borderRadius } = props
  const bg = useColorModeValue('utils.200', 'utils.dark.elevated.12dp')
  return (
        <Center borderRadius={borderRadius ? 8 : 0} bg={bg} w={`${width}px` ?? ''} aspectRatio={'1/1'}>
            <FaImage size={'50%'}/>
        </Center>
  )
}
