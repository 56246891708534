import { type Delivery } from '../model'
import { deliveryService } from '../../services/delivery-service'

export const DeliveryStore = () => ({
  deliveries: [] as Delivery[],
  getDeliveries () {
    return this.deliveries
  },
  setDeliveries (deliveries: Delivery[]) {
    this.deliveries = deliveries
  },
  async refreshDeliveries () {
    const deliveries = await deliveryService.getAllDeliveries()
    this.setDeliveries(deliveries)
  },
  async onInitialize () {
    const deliveries = await deliveryService.getAllDeliveries()
    this.setDeliveries(deliveries)
  }
})
