import {
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement, Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaMinus } from 'react-icons/fa'

interface Props {
  optionKey: string
  price: number
  updateKey: (key: string, newKey: string) => void
  updatePrice: (key: string, price: number) => void
  deleteOption: (key: string) => void
}

export const MenuOption = (props: Props) => {
  const { optionKey, price, updatePrice, updateKey, deleteOption } = props
  const [option, setOption] = useState(optionKey)
  const [newPrice, setNewPrice] = useState(price)
  const [priceInput, setPriceInput] = useState(`${price}`)

  // useEffect(() => {
  //
  // },[newPrice])

  return (
        <Grid templateColumns={'2fr 1fr'} gap={4} w={'20rem'}>
            <InputGroup>
                <InputLeftElement>
                    <IconButton aria-label={`remove option-item ${option}`} isRound icon={<FaMinus size={'.5em'}/>} onClick={() => { deleteOption(option) }} p={'6px'} size={'2sm'} variant={'utilsButtonDelete'}/>
                </InputLeftElement>
                <Input value={option} onChange={(e) => { setOption(e.target.value) }} onBlur={() => { updateKey(optionKey, option) }} />
            </InputGroup>
            <InputGroup>
                <InputLeftElement>
                    <Text color={'utils.gray'}>{'€'}</Text>
                </InputLeftElement>
                <Input type={'number'} value={priceInput} onChange={(e) => {
                  setPriceInput(e.target.value)
                  setNewPrice(Number(e.target.value))
                }} onBlur={() => {
                  setPriceInput(`${Number(priceInput)}`)
                  updatePrice(option, newPrice)
                }}/>
            </InputGroup>
        </Grid>
  )
}
