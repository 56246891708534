import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    // ...
  },
  track: {
    _checked: {
      bg: 'utils.900'
    }
  }
})
export const switchTheme = defineMultiStyleConfig({ baseStyle })
