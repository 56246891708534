import { type Order } from '../../../../shared/model'
import React from 'react'
import { Box, HStack, Image, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { NoImagePlaceholder } from '../../../../shared/components/NoImagePlaceholder'

interface Props {
  order: Order
}

export const OrderCard = (props: Props) => {
  const { order } = props
  const optionsText = useColorModeValue('gray.500', '#e8e7e6ab')
  const getOptions = () => {
    const options: string[] = []
    Object.entries(order.chosenOptions).map(([type, option]) => (
      options.push(option)
    ))
    return options.join(', ')
  }

  return (
      <Box>
          <HStack height={'inherit'}>
              {order.menuItem.image !== '' && order.menuItem.image
                ? <Image borderRadius={'lg'} h={'40px'} objectFit={'cover'} aspectRatio={'1/1'} src={`data:image/jpeg;base64,${order.menuItem.image}`}
                                         alt={order.menuItem.name}/>
                : <NoImagePlaceholder width={40} borderRadius/>
              }

              <VStack h={'100%'} alignItems={'start'} justifyContent={'center'} gap={0}>
          <Text fontSize={'sm'} fontWeight={400}>
              {`${order.menuItem.name}${order.amount > 1 ? ` (${order.amount}x)` : ''}`}
          </Text>
          <Text fontSize="xs" color={optionsText} noOfLines={1}>
              {`${getOptions()} ${getOptions().length > 0 ? '-' : ''} ${order.user}`}
          </Text>
      </VStack>
            </HStack>
        </Box>
  )
}
