import { asGlobalStore } from '../utils/stores'
import { type Tenant } from '../model'
import { tenantsService } from '../../services/tenant-service'

const createStore = () => ({
  tenants: [] as Tenant[],
  getTenants () {
    return this.tenants
  },
  setTenants (tenants: Tenant[]) {
    this.tenants = tenants
  },
  async onInitialize () {
    const tenants = await tenantsService.getTenants()
    this.setTenants(tenants)
  }
})
export const TenantStore = asGlobalStore(createStore())
